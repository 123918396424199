<div id="create-registration">
    <div>
        <h1 style="text-align: center;">Create a registration</h1>
    </div>
    <div *ngIf="showErrorMessage" class="error-alert">
        <p class="error-message"> {{errorMessage}} </p>
    </div>
    <div *ngIf="showSuccessMessage" class="success-alert">
        <p class="success-message"> {{successMessage}} </p>
    </div>
    <div class="create-registration">
        <input [(ngModel)]="createRegistration.shipId" type="text" placeholder="Ship id">
        <div class="create-registration-buttons">
            <button class="btn btn-create" (click)="createAuthenticationRegistration()">Create</button>
            <button class="btn btn-normal" (click)="pollAuthenticationRegistration()">Fetch status</button>
        </div>
    </div>
</div>