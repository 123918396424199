<div class="registration">
    <div class="registration-info flex-item">
        <p class="ship-id">{{registration.shipId}}</p>
    </div>
    <div class="mapping-info flex-item">
        <input *ngIf="!registration.shipInformation?.shipName || registration.status === 'awaiting_status'" id="ship-name" class="ship-name input" type="text" placeholder="ship name" [(ngModel)]="registration.shipName">
        <input *ngIf="!registration.shipInformation?.clientName || registration.status === 'awaiting_status'" id="client-name" class="client-name input" type="text" placeholder="client name" [(ngModel)]="registration.clientName">
        <p *ngIf="registration.shipInformation?.shipName && registration.status !== 'awaiting_status'">ship name: {{registration.shipInformation?.shipName}}</p>
        <p *ngIf="registration.shipInformation?.clientName && registration.status !== 'awaiting_status'">client name: {{registration.shipInformation?.clientName}}</p>
    </div>  
    <div class="flex-item status">
        <p>{{registration.status}}</p>
    </div>
    <div *ngIf="registration?.status === 'awaiting_status'" class="registration-actions flex-item">
        <button (click)="acceptRegistration(registration)" class="btn btn-accept">Accept</button>
        <button (click)="denyRegistration(registration)" class="btn btn-deny">Deny</button>
    </div>
    <div *ngIf="registration.status !== 'awaiting_status'" class="registration-actions flex-item">
        <button class="btn" (click)="resetRegistration(registration)">Reset registration</button>
        <button class="btn" (click)="revokeCertificate(registration)">Revoke Certificate</button>
    </div>
</div>